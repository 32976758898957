@media screen and (max-width: 640px) {
  body {
    font-size: 11px;
    color: #555;
    margin: 0px;
    padding: 0px;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    line-height: 16px;
    word-break: break-all;

    a {
      color: #333;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #head {
    background-image: url(common/head_bg.gif);
    background-repeat: repeat-x;
    background-position: bottom;
    height: auto;
    width: 100%;
    overflow: hidden;

    .discription {
      font-size: 9px;
      line-height: 20px;
      color: #FFF;
      background-image: url(common/discription_bg.gif);
      background-repeat: repeat-x;
      height: 20px;
      text-align: center;
      min-width: 100%;
    }

    .toplogo {
      margin: auto;
      padding: 0px;
      float: none;
      width: 310px;

      img {
        width: 320px;
        height: auto;
      }
    }

    .headinner {
      margin: auto;
      padding: 0 10px;
      width: auto;

      .toplink {
        text-align: center;
        padding: 5px 0px;
        display: none;

        a {
          color: #604C41;
          text-decoration: none;
        }
      }

      .tel {
        text-align: center;
        margin: 0 0 10px;

        a img {
          height: 24px;
          width: auto;
          margin: 0 0 0 5px;
        }
      }
    }
  }

  #gmenu {
    background-image: none;
    background-repeat: repeat-x;
    height: auto;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    border-left: 1px solid #AAA;

    ul {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0px;
      list-style-type: none;
      border-left: none;
      overflow: auto;

      li {
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        color: #FFF;
        display: block;
        float: left;
        width: 25%;
        height: auto;
        transition-duration: none;
        transition-timing-function: none;
        background-image: url(common/gmenu_sp_bg.png);
        background-size: 100% 100%;

        &:hover {
          background-color: none;
          opacity: 0.8;
        }

        a {
          display: block;
          padding: 10px 5px 15px;
          height: 38px;
          text-align: center;
          border-right: 1px solid #AAA;
          color: #5F4C3F;
        }
      }

      .facilities, .type, .simulation, .faq {
        background-image: url(common/gmenu_sp_bg.png);
      }
    }
  }

  #indeximg {
    .bxslider {
      margin: 0px;
      padding: 0px;
    }

    .bx-wrapper .bx-viewport {
      margin: 0px;
      padding: 0px;
      border: none;
      left: 0px;
    }
  }

  #facilityimg {
    margin: 20px 4% 20px;
    padding: 0px;
    width: 92%;
    overflow: hidden;

    .slider2 {
      margin: 0px;
      padding: 0px;
    }

    .bx-wrapper {
      .bx-viewport {
        margin: 0px;
        padding: 0px;
        border: none;
        left: 0px;
        box-shadow: none;
      }

      .bx-prev {
        left: -40px;
      }

      .bx-next {
        right: -40px;
      }
    }
  }

  #wrap {
    margin: 0 auto 10px;
    padding: 0 0px 130px;
    width: auto;
    overflow: auto;
    background-image: url(common/wrap_bottom_bg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 140px auto;
  }

  #foot {
    margin: 0;
    padding: 20px 10px 10px;
    background-color: #D99D47;
    min-width: 300px;

    .footlogo {
      img {
        width: 240px;
      }

      float: none;
      text-align: center;
    }

    .addr {
      font-size: 11px;
      line-height: 16px;
      color: #FFF;
      margin: 10px 0;
      text-align: center;

      span {
        display: block;
        margin: 0 0 -16px;
      }
    }
  }

  .shinobu {
    text-align: center;
    padding: 10px 10px 30px;

    .shinobutitle {
      font-size: 14px;
      line-height: 22px;
      font-weight: bold;
    }

    h1 {
      margin: 30px 0px;

      img {
        width: 360px;
        max-width: 100%;
      }
    }

    .taiseimain img {
      width: 740px;
      max-width: 100%;
    }

    p {
      text-align: left;
      margin: 30px 0;
      font-size: 11px;
    }

    .bot a {
      color: #FFF;
      background-color: #00913A;
      display: inline-block;
      padding: 7px 10px;
      border-radius: 5px;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;

      &:hover {
        background-color: #9DD645;
        text-decoration: none;
      }
    }
  }

  #wrap {
    #content {
      float: none;
      width: 100%;

      .indexbnr ul {
        margin: 0px;
        padding: 10px 10px 0;
        list-style-type: none;

        li {
          display: block;
          margin: 0 0 10px;
          padding: 0px;

          a img {
            width: 100%;
          }
        }
      }

      .col {
        h2 {
          border-bottom: 1px solid #CCBC99;
          border-top: 5px solid #604C41;
          padding: 10px 10px 8px;
          border-radius: 0;
          color: #604C41;
          background: -moz-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);

          /* FF3.6+ */
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f2ee), color-stop(100%, #e6e3d9));

          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);

          /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);

          /* Opera 11.10+ */
          background: -ms-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);

          /* IE10+ */
          background: linear-gradient(to bottom, #f4f2ee 0%, #e6e3d9 100%);

          /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f2ee', endColorstr='#e6e3d9',GradientType=0 );

          /* IE6-9 */
          font-size: 14px;
          line-height: 20px;
          margin: 0;
        }

        p {
          margin: 10px;
        }
      }
    }

    #sidebar {
      .tel a img {
        width: 90%;
        margin: 20px 5%;
      }

      .scol {
        width: 100%;
        border: none;
        margin: 0;
        padding: 0px;

        ul li a {
          word-break: break-all;
        }

        h2 {
          color: #FFF;
          background-color: #AA996D;
          border-bottom: 5px solid #604C41;
          margin: 0px;
          padding: 10px 10px 8px;
          background: #a59982;

          /* Old browsers */
          background: -moz-linear-gradient(top, #a59982 0%, #afa38e 100%);

          /* FF3.6+ */
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a59982), color-stop(100%, #afa38e));

          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(top, #a59982 0%, #afa38e 100%);

          /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(top, #a59982 0%, #afa38e 100%);

          /* Opera 11.10+ */
          background: -ms-linear-gradient(top, #a59982 0%, #afa38e 100%);

          /* IE10+ */
          background: linear-gradient(to bottom, #a59982 0%, #afa38e 100%);

          /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a59982', endColorstr='#afa38e',GradientType=0 );

          /* IE6-9 */
          font-size: 14px;
          line-height: 20px;
        }
      }

      width: auto;
      float: none;
      overflow: auto;
      margin: 0;

      .news.scol ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        background-color: #EFEDDF;

        li a {
          padding: 10px;
          display: block;
          border-bottom: 1px solid #AAA;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
          line-height: 18px;

          &:hover {
            text-decoration: none;
            background-color: #FAF8F4;
          }

          span {
            display: block;
          }
        }
      }

      .faqlink.scol {
        margin: 0px;
        padding: 0px;
        display: none;

        ul {
          margin: 0px;
          padding: 0px;
          list-style-type: none;
          background-color: #EFEDDF;

          li a {
            padding: 10px 6px 10px 35px;
            display: block;
            border-bottom: 1px solid #AAA;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
            line-height: 18px;
            background-image: url(common/faqlink_arrow.png);
            background-repeat: no-repeat;
            background-position: 8px center;

            &:hover {
              text-decoration: none;
              background-color: #FAF8F4;
            }
          }
        }
      }

      .price.scol {
        margin: 0 0 0px;
        padding: 0px;

        dl {
          margin: 0;
          padding: 5px 0 0;
          list-style-type: none;
          background-color: #EFEDDF;

          p {
            font-size: 11px;
            line-height: 16px;
            margin: 0;
            border-bottom: 1px solid #AAA;
            padding: 5px 10px;
          }

          dd {
            padding: 10px;
            margin: 0;
            display: block;
            border-bottom: 1px solid #AAA;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
            line-height: 18px;
            text-align: right;
          }

          dt {
            padding: 10px;
            line-height: 18px;
            display: block;
            float: left;
          }
        }
      }

      .point4 dl {
        margin: 0;

        dt {
          color: #FFF;
          background-color: #604C41;
          float: left;
          width: 14px;
          text-align: center;
          clear: left;
          margin: 8px;
          border-radius: 3px;
        }

        dd {
          padding: 8px 8px 8px 30px;
          margin: 0px;
          border-bottom: 1px solid #CCC;
        }
      }
    }
  }

  #pagetitle {
    background-image: url(common/pagetitlebg.png);
    background-size: 100%;
    height: auto;
    margin: 0 0 2px;

    h1 {
      font-family: "ＭＳ Ｐ明朝", "MS PMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
      font-size: 20px;
      line-height: 28px;
      color: #FFF;
      margin: 0 auto;
      padding: 10px;
      width: auto;
    }
  }

  #wrap #content .col {
    margin: 0 0 0px;
    padding: 0;

    .collist {
      margin: 0px;
      padding: 0;
      list-style-type: none;

      li {
        padding: 10px 0 0px;
        border-bottom: 1px solid #CCC;
        overflow: auto;
        margin: 0;

        h3 {
          font-size: 14px;
          line-height: 18px;
          margin: 0 0 5px 10px;
          padding: 0px;
        }
      }
    }
  }

  .textbot a {
    display: inline-block;
    color: #FFF;
    margin: 0px;
    padding: 0 20px;
    height: 30px;
    background-color: #A59A82;
    line-height: 30px;
    text-align: center;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;

    &:hover {
      background-color: #5A4E3B;
      text-decoration: none;
    }
  }

  #wrap {
    #content .col .collist li {
      img {
        width: 600px;
      }

      .alignright {
        width: 200px;
      }
    }

    #sidebar .haikai img {
      width: 90%;
      margin: 20px 5%;
    }

    #content .col {
      .faq {
        margin: 10px 0 0px;
        padding: 0px;
        list-style-type: none;

        li {
          margin: 0px;
          padding: 0px;
          border-bottom: 1px solid #CCC;

          h3 {
            font-size: 14px;
            line-height: 20px;
            background-image: url(common/faq_open.png);
            background-repeat: no-repeat;
            background-position: right 10px;
            background-size: 43px 20px;
            margin: 0 0 12px;
            padding: 12px 80px 0 10px;
            cursor: pointer;

            &.faqselected {
              background-image: url(common/faq_close.png);
            }
          }
        }
      }

      .contactform {
        border-top: 1px solid #CCC;
        margin: 0;
        font-size: 12px;
        line-height: 20px;
        width: 100%;

        dt {
          float: none;
          clear: left;
          margin: 0px;
          padding: 10px 10px 0;
          display: block;
        }

        dd {
          margin: 0px;
          padding: 0px 10px 10px;
          border-bottom: 1px solid #CCC;
          font-size: 12px;

          &:nth-of-type(odd) {
            background: #FFF;
          }

          input, textarea {
            border: 1px solid #999;
            font-size: 12px;
            line-height: 20px;
          }
        }

        .csubmit {
          font-size: 14px;
          padding: 5px 30px;
          margin: 20px auto 0;
          display: block;
          border: 1px solid #AAA;
          background-color: #EEE;
          border-radius: 3px;

          &:hover {
            background-color: #CCC;
          }
        }
      }

      .simulationform {
        border-top: 1px solid #CCC;
        margin: 0 0 40px 0;
        font-size: 12px;
        line-height: 20px;
        width: 100%;
		background-color: white;

        dt {
          float: none;
          clear: left;
          margin: 0px;
          padding: 10px 10px 0;
          display: block;
        }

        dd {
          margin: 0px;
          padding: 0px 10px 10px;
          border-bottom: 1px solid #CCC;
          font-size: 12px;

          &:nth-of-type(odd) {
            background: #FFF;
          }

          input {
            border: 1px solid #999;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

      .simulation_care {
        border-top: 1px solid #CCC;
        margin: 0 auto;
        width: 100%;

        dt {
          display: table-cell;
          float: left;
          margin: 0;
          padding: 1% 2%;
          border-bottom: 1px solid #CCC;
          font-size: 12px;
          width: 46%;
        }

        dd {
          display: table-cell;
          float: right;
          margin: 0;
          padding: 1% 2%;
          border-bottom: 1px solid #CCC;
          font-size: 12px;
          width: 46%;

          &:nth-of-type(odd) {
            background: #FFF;
          }
        }
      }

      .datalist {
        border-top: 1px solid #CCC;
        margin: 0;
        font-size: 12px;
        line-height: 20px;
		background-color: #FFF;

        dt {
          float: none;
          clear: left;
          margin: 0px;
          padding: 10px 10px 0;
          display: block;
          font-weight: bold;
          color: #393323;
        }

        dd {
          margin: 0px;
          padding: 0px 10px 10px;
          border-bottom: 1px solid #CCC;
          font-size: 12px;

          &:nth-of-type(odd) {
            background: #FFF;
          }
        }
      }
    }
  }

  .shinobu {
    .bosyumain img {
      width: 400px;
    }

    .room50 img {
      margin: 0 auto 20px;
      padding: 0px;
      width: 140px;
    }

    .bosyu {
      border: 2px solid #393323;
      color: #393323;
      display: inline-block;
      font-size: 30px;
      line-height: 40px;
      width: 300px;
      max-width: 100%;
      margin: 20px auto;
      padding: 0px;
    }

    dl {
      text-align: left;
      border-top: 1px solid #CCC;
      margin: 40px 0;

      p {
        margin: 0px;
        padding: 5px 0;
      }

      dt {
        float: left;
        color: #FFF;
        background-color: #393323;
        font-size: 11px;
        line-height: 20px;
        width: 20px;
        text-align: center;
        margin: 10px 0 0 10px;
        border-radius: 2px;
      }

      dd {
        font-size: 14px;
        line-height: 20px;
        margin: 0px;
        padding: 10px 10px 10px 40px;
        border-bottom: 1px solid #CCC;
      }
    }

    .tel a img {
      width: 280px;
      min-width: 100%;
      margin: 10px 0 20px;
    }

    .bot.cform a {
      background-color: #4E4631;

      &:hover {
        background-color: #393323;
      }
    }
  }

  img {
    &.alignright {
      float: right;
      margin: 0 10px 10px 10px;
      padding: 0px;
      max-width: 45%;
      height: auto;
    }

    &.alignleft {
      float: left;
      margin: 0 10px 10px 10px;
      padding: 0px;
      max-width: 45%;
      height: auto;
    }
  }

  .end {}
}
