@charset "UTF-8";
@media screen and (max-width: 640px) {
  body {
    font-size: 11px;
    color: #555;
    margin: 0px;
    padding: 0px;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    line-height: 16px;
    word-break: break-all; }
    body a {
      color: #333;
      text-decoration: none; }
      body a:hover {
        text-decoration: none; }
  img {
    max-width: 100%;
    height: auto; }
  #head {
    background-image: url(common/head_bg.gif);
    background-repeat: repeat-x;
    background-position: bottom;
    height: auto;
    width: 100%;
    overflow: hidden; }
    #head .discription {
      font-size: 9px;
      line-height: 20px;
      color: #FFF;
      background-image: url(common/discription_bg.gif);
      background-repeat: repeat-x;
      height: 20px;
      text-align: center;
      min-width: 100%; }
    #head .toplogo {
      margin: auto;
      padding: 0px;
      float: none;
      width: 310px; }
      #head .toplogo img {
        width: 320px;
        height: auto; }
    #head .headinner {
      margin: auto;
      padding: 0 10px;
      width: auto; }
      #head .headinner .toplink {
        text-align: center;
        padding: 5px 0px;
        display: none; }
        #head .headinner .toplink a {
          color: #604C41;
          text-decoration: none; }
      #head .headinner .tel {
        text-align: center;
        margin: 0 0 10px; }
        #head .headinner .tel a img {
          height: 24px;
          width: auto;
          margin: 0 0 0 5px; }
  #gmenu {
    background-image: none;
    background-repeat: repeat-x;
    height: auto;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    border-left: 1px solid #AAA; }
    #gmenu ul {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0px;
      list-style-type: none;
      border-left: none;
      overflow: auto; }
      #gmenu ul li {
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        color: #FFF;
        display: block;
        float: left;
        width: 25%;
        height: auto;
        transition-duration: none;
        transition-timing-function: none;
        background-image: url(common/gmenu_sp_bg.png);
        background-size: 100% 100%; }
        #gmenu ul li:hover {
          background-color: none;
          opacity: 0.8; }
        #gmenu ul li a {
          display: block;
          padding: 10px 5px 15px;
          height: 38px;
          text-align: center;
          border-right: 1px solid #AAA;
          color: #5F4C3F; }
      #gmenu ul .facilities, #gmenu ul .type, #gmenu ul .simulation, #gmenu ul .faq {
        background-image: url(common/gmenu_sp_bg.png); }
  #indeximg .bxslider {
    margin: 0px;
    padding: 0px; }
  #indeximg .bx-wrapper .bx-viewport {
    margin: 0px;
    padding: 0px;
    border: none;
    left: 0px; }
  #facilityimg {
    margin: 20px 4% 20px;
    padding: 0px;
    width: 92%;
    overflow: hidden; }
    #facilityimg .slider2 {
      margin: 0px;
      padding: 0px; }
    #facilityimg .bx-wrapper .bx-viewport {
      margin: 0px;
      padding: 0px;
      border: none;
      left: 0px;
      box-shadow: none; }
    #facilityimg .bx-wrapper .bx-prev {
      left: -40px; }
    #facilityimg .bx-wrapper .bx-next {
      right: -40px; }
  #wrap {
    margin: 0 auto 10px;
    padding: 0 0px 130px;
    width: auto;
    overflow: auto;
    background-image: url(common/wrap_bottom_bg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 140px auto; }
  #foot {
    margin: 0;
    padding: 20px 10px 10px;
    background-color: #D99D47;
    min-width: 300px; }
    #foot .footlogo {
      float: none;
      text-align: center; }
      #foot .footlogo img {
        width: 240px; }
    #foot .addr {
      font-size: 11px;
      line-height: 16px;
      color: #FFF;
      margin: 10px 0;
      text-align: center; }
      #foot .addr span {
        display: block;
        margin: 0 0 -16px; }
  .shinobu {
    text-align: center;
    padding: 10px 10px 30px; }
    .shinobu .shinobutitle {
      font-size: 14px;
      line-height: 22px;
      font-weight: bold; }
    .shinobu h1 {
      margin: 30px 0px; }
      .shinobu h1 img {
        width: 360px;
        max-width: 100%; }
    .shinobu .taiseimain img {
      width: 740px;
      max-width: 100%; }
    .shinobu p {
      text-align: left;
      margin: 30px 0;
      font-size: 11px; }
    .shinobu .bot a {
      color: #FFF;
      background-color: #00913A;
      display: inline-block;
      padding: 7px 10px;
      border-radius: 5px;
      transition-duration: 0.3s;
      transition-timing-function: ease-out; }
      .shinobu .bot a:hover {
        background-color: #9DD645;
        text-decoration: none; }
  #wrap #content {
    float: none;
    width: 100%; }
    #wrap #content .indexbnr ul {
      margin: 0px;
      padding: 10px 10px 0;
      list-style-type: none; }
      #wrap #content .indexbnr ul li {
        display: block;
        margin: 0 0 10px;
        padding: 0px; }
        #wrap #content .indexbnr ul li a img {
          width: 100%; }
    #wrap #content .col h2 {
      border-bottom: 1px solid #CCBC99;
      border-top: 5px solid #604C41;
      padding: 10px 10px 8px;
      border-radius: 0;
      color: #604C41;
      background: -moz-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f2ee), color-stop(100%, #e6e3d9));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(top, #f4f2ee 0%, #e6e3d9 100%);
      /* IE10+ */
      background: linear-gradient(to bottom, #f4f2ee 0%, #e6e3d9 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f2ee', endColorstr='#e6e3d9',GradientType=0 );
      /* IE6-9 */
      font-size: 14px;
      line-height: 20px;
      margin: 0; }
    #wrap #content .col p {
      margin: 10px; }
  #wrap #sidebar {
    width: auto;
    float: none;
    overflow: auto;
    margin: 0; }
    #wrap #sidebar .tel a img {
      width: 90%;
      margin: 20px 5%; }
    #wrap #sidebar .scol {
      width: 100%;
      border: none;
      margin: 0;
      padding: 0px; }
      #wrap #sidebar .scol ul li a {
        word-break: break-all; }
      #wrap #sidebar .scol h2 {
        color: #FFF;
        background-color: #AA996D;
        border-bottom: 5px solid #604C41;
        margin: 0px;
        padding: 10px 10px 8px;
        background: #a59982;
        /* Old browsers */
        background: -moz-linear-gradient(top, #a59982 0%, #afa38e 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a59982), color-stop(100%, #afa38e));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #a59982 0%, #afa38e 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, #a59982 0%, #afa38e 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top, #a59982 0%, #afa38e 100%);
        /* IE10+ */
        background: linear-gradient(to bottom, #a59982 0%, #afa38e 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a59982', endColorstr='#afa38e',GradientType=0 );
        /* IE6-9 */
        font-size: 14px;
        line-height: 20px; }
    #wrap #sidebar .news.scol ul {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      background-color: #EFEDDF; }
      #wrap #sidebar .news.scol ul li a {
        padding: 10px;
        display: block;
        border-bottom: 1px solid #AAA;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        line-height: 18px; }
        #wrap #sidebar .news.scol ul li a:hover {
          text-decoration: none;
          background-color: #FAF8F4; }
        #wrap #sidebar .news.scol ul li a span {
          display: block; }
    #wrap #sidebar .faqlink.scol {
      margin: 0px;
      padding: 0px;
      display: none; }
      #wrap #sidebar .faqlink.scol ul {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        background-color: #EFEDDF; }
        #wrap #sidebar .faqlink.scol ul li a {
          padding: 10px 6px 10px 35px;
          display: block;
          border-bottom: 1px solid #AAA;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
          line-height: 18px;
          background-image: url(common/faqlink_arrow.png);
          background-repeat: no-repeat;
          background-position: 8px center; }
          #wrap #sidebar .faqlink.scol ul li a:hover {
            text-decoration: none;
            background-color: #FAF8F4; }
    #wrap #sidebar .price.scol {
      margin: 0 0 0px;
      padding: 0px; }
      #wrap #sidebar .price.scol dl {
        margin: 0;
        padding: 5px 0 0;
        list-style-type: none;
        background-color: #EFEDDF; }
        #wrap #sidebar .price.scol dl p {
          font-size: 11px;
          line-height: 16px;
          margin: 0;
          border-bottom: 1px solid #AAA;
          padding: 5px 10px; }
        #wrap #sidebar .price.scol dl dd {
          padding: 10px;
          margin: 0;
          display: block;
          border-bottom: 1px solid #AAA;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
          line-height: 18px;
          text-align: right; }
        #wrap #sidebar .price.scol dl dt {
          padding: 10px;
          line-height: 18px;
          display: block;
          float: left; }
    #wrap #sidebar .point4 dl {
      margin: 0; }
      #wrap #sidebar .point4 dl dt {
        color: #FFF;
        background-color: #604C41;
        float: left;
        width: 14px;
        text-align: center;
        clear: left;
        margin: 8px;
        border-radius: 3px; }
      #wrap #sidebar .point4 dl dd {
        padding: 8px 8px 8px 30px;
        margin: 0px;
        border-bottom: 1px solid #CCC; }
  #pagetitle {
    background-image: url(common/pagetitlebg.png);
    background-size: 100%;
    height: auto;
    margin: 0 0 2px; }
    #pagetitle h1 {
      font-family: "ＭＳ Ｐ明朝", "MS PMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
      font-size: 20px;
      line-height: 28px;
      color: #FFF;
      margin: 0 auto;
      padding: 10px;
      width: auto; }
  #wrap #content .col {
    margin: 0 0 0px;
    padding: 0; }
    #wrap #content .col .collist {
      margin: 0px;
      padding: 0;
      list-style-type: none; }
      #wrap #content .col .collist li {
        padding: 10px 0 0px;
        border-bottom: 1px solid #CCC;
        overflow: auto;
        margin: 0; }
        #wrap #content .col .collist li h3 {
          font-size: 14px;
          line-height: 18px;
          margin: 0 0 5px 10px;
          padding: 0px; }
  .textbot a {
    display: inline-block;
    color: #FFF;
    margin: 0px;
    padding: 0 20px;
    height: 30px;
    background-color: #A59A82;
    line-height: 30px;
    text-align: center;
    transition-duration: 0.3s;
    transition-timing-function: ease-out; }
    .textbot a:hover {
      background-color: #5A4E3B;
      text-decoration: none; }
  #wrap #content .col .collist li img {
    width: 600px; }
  #wrap #content .col .collist li .alignright {
    width: 200px; }
  #wrap #sidebar .haikai img {
    width: 90%;
    margin: 20px 5%; }
  #wrap #content .col .faq {
    margin: 10px 0 0px;
    padding: 0px;
    list-style-type: none; }
    #wrap #content .col .faq li {
      margin: 0px;
      padding: 0px;
      border-bottom: 1px solid #CCC; }
      #wrap #content .col .faq li h3 {
        font-size: 14px;
        line-height: 20px;
        background-image: url(common/faq_open.png);
        background-repeat: no-repeat;
        background-position: right 10px;
        background-size: 43px 20px;
        margin: 0 0 12px;
        padding: 12px 80px 0 10px;
        cursor: pointer; }
        #wrap #content .col .faq li h3.faqselected {
          background-image: url(common/faq_close.png); }
  #wrap #content .col .contactform {
    border-top: 1px solid #CCC;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    width: 100%; }
    #wrap #content .col .contactform dt {
      float: none;
      clear: left;
      margin: 0px;
      padding: 10px 10px 0;
      display: block; }
    #wrap #content .col .contactform dd {
      margin: 0px;
      padding: 0px 10px 10px;
      border-bottom: 1px solid #CCC;
      font-size: 12px; }
      #wrap #content .col .contactform dd:nth-of-type(odd) {
        background: #FFF; }
      #wrap #content .col .contactform dd input, #wrap #content .col .contactform dd textarea {
        border: 1px solid #999;
        font-size: 12px;
        line-height: 20px; }
    #wrap #content .col .contactform .csubmit {
      font-size: 14px;
      padding: 5px 30px;
      margin: 20px auto 0;
      display: block;
      border: 1px solid #AAA;
      background-color: #EEE;
      border-radius: 3px; }
      #wrap #content .col .contactform .csubmit:hover {
        background-color: #CCC; }
  #wrap #content .col .simulationform {
    border-top: 1px solid #CCC;
    margin: 0 0 40px 0;
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    background-color: white; }
    #wrap #content .col .simulationform dt {
      float: none;
      clear: left;
      margin: 0px;
      padding: 10px 10px 0;
      display: block; }
    #wrap #content .col .simulationform dd {
      margin: 0px;
      padding: 0px 10px 10px;
      border-bottom: 1px solid #CCC;
      font-size: 12px; }
      #wrap #content .col .simulationform dd:nth-of-type(odd) {
        background: #FFF; }
      #wrap #content .col .simulationform dd input {
        border: 1px solid #999;
        font-size: 12px;
        line-height: 20px; }
  #wrap #content .col .simulation_care {
    border-top: 1px solid #CCC;
    margin: 0 auto;
    width: 100%; }
    #wrap #content .col .simulation_care dt {
      display: table-cell;
      float: left;
      margin: 0;
      padding: 1% 2%;
      border-bottom: 1px solid #CCC;
      font-size: 12px;
      width: 46%; }
    #wrap #content .col .simulation_care dd {
      display: table-cell;
      float: right;
      margin: 0;
      padding: 1% 2%;
      border-bottom: 1px solid #CCC;
      font-size: 12px;
      width: 46%; }
      #wrap #content .col .simulation_care dd:nth-of-type(odd) {
        background: #FFF; }
  #wrap #content .col .datalist {
    border-top: 1px solid #CCC;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    background-color: #FFF; }
    #wrap #content .col .datalist dt {
      float: none;
      clear: left;
      margin: 0px;
      padding: 10px 10px 0;
      display: block;
      font-weight: bold;
      color: #393323; }
    #wrap #content .col .datalist dd {
      margin: 0px;
      padding: 0px 10px 10px;
      border-bottom: 1px solid #CCC;
      font-size: 12px; }
      #wrap #content .col .datalist dd:nth-of-type(odd) {
        background: #FFF; }
  .shinobu .bosyumain img {
    width: 400px; }
  .shinobu .room50 img {
    margin: 0 auto 20px;
    padding: 0px;
    width: 140px; }
  .shinobu .bosyu {
    border: 2px solid #393323;
    color: #393323;
    display: inline-block;
    font-size: 30px;
    line-height: 40px;
    width: 300px;
    max-width: 100%;
    margin: 20px auto;
    padding: 0px; }
  .shinobu dl {
    text-align: left;
    border-top: 1px solid #CCC;
    margin: 40px 0; }
    .shinobu dl p {
      margin: 0px;
      padding: 5px 0; }
    .shinobu dl dt {
      float: left;
      color: #FFF;
      background-color: #393323;
      font-size: 11px;
      line-height: 20px;
      width: 20px;
      text-align: center;
      margin: 10px 0 0 10px;
      border-radius: 2px; }
    .shinobu dl dd {
      font-size: 14px;
      line-height: 20px;
      margin: 0px;
      padding: 10px 10px 10px 40px;
      border-bottom: 1px solid #CCC; }
  .shinobu .tel a img {
    width: 280px;
    min-width: 100%;
    margin: 10px 0 20px; }
  .shinobu .bot.cform a {
    background-color: #4E4631; }
    .shinobu .bot.cform a:hover {
      background-color: #393323; }
  img.alignright {
    float: right;
    margin: 0 10px 10px 10px;
    padding: 0px;
    max-width: 45%;
    height: auto; }
  img.alignleft {
    float: left;
    margin: 0 10px 10px 10px;
    padding: 0px;
    max-width: 45%;
    height: auto; } }
